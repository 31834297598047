import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['output']

	connect() {}

	new() {
		this.outputTarget.textContent = `Hello!`
	}
}
